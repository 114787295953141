<template>
  <el-dropdown :hide-on-click="false"
               :show-timeout="0"
               class="lang-select"
               @command="changeLang"
  >
    <div>
      <fa-icon :size="size"
               icon="language"
      />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="lang in langs"
                        :key="lang.code"
                        :command="lang.code"
                        :disabled="appLang === lang.code"
      >{{ lang.name }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'LangSelect',
    props: {
      size: {
        type: String,
        default: null,
        validator(value) {
          return (
            [
              'lg',
              'xs',
              'sm',
              '1x',
              '2x',
              '3x',
              '4x',
              '5x',
              '6x',
              '7x',
              '8x',
              '9x',
              '10x',
            ].indexOf(value) > -1
          );
        },
      },
    },
    data() {
      return {
        langs: [
          {
            code: 'zh-TW',
            name: '中文',
          },
          {
            code: 'en-US',
            name: 'English',
          },
        ],
      };
    },
    computed: {
      ...mapGetters(['appLang']),
    },
    methods: {
      ...mapActions(['changeLang']),
    },
  };
</script>

<style lang="scss" scoped>
  .lang-select {
    color: #3c8dbc;
    cursor: pointer;
  }
</style>
