<template>
  <el-button v-bind="$props"
             class="el-button-full"
  >
    <slot/>
  </el-button>
</template>

<script>
  import { Button } from 'element-ui';

  export default {
    name: 'ElButtonFull',
    extends: Button,
  };
</script>

<style scoped>
  .el-button-full {
    width: 100%;
  }
</style>
