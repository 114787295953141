<template>
  <div class="login-page">
    <transition name="el-zoom-in-top">

      <el-row :xs="8"
              class="login-container"
              type="flex"
              justify="center"
              align="middle"
      >
        <lang-select class="lang-select"
                     size="2x"
        />
        <div class="Coretronic-FMS">FMS

        </div>
        <el-col type="flex"
                justify="space-between"
                align="middle"
        >
          <div style="height:134px;">
          </div>
          <el-card class="login-card">
            <el-form :model="authData"
                     @submit.native.prevent="onSignIn"
            >
              <el-form-item label="">
                <div class="login-image">
                  <div class="welcome">確定執行任務？</div>
                  <img src="/asserts/icon/logo/drone-skywatch-log.svg"
                       alt="placeholder image"
                  >
                </div>
              </el-form-item>
              <el-form-item>
                <el-input v-model="authData.password"
                          :placeholder="$t('login.password')"
                          type="password"
                />
              </el-form-item>

              <el-form-item>
                <el-button :loading="loading"
                           type="info"
                >取消</el-button>
                <el-button :loading="loading"
                           type="primary"
                           native-type="submit"
                >確認</el-button>
              </el-form-item>

            </el-form>

          </el-card>
          <div style="height:55px;">

          </div>
          <div class="about-body">
            <p>Version: {{ config.version.describe }}</p>
            <p>API Version: {{ config.apiVersion }}</p>
            <p>CIRC &copy; 2018  {{ $t('login.right_reserved') }}</p>
          </div>
        </el-col>
      </el-row>
    </transition>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { AuthData } from '@/models/account';
  import { logger } from '@/logger/index';
  import ElButtonFull from '@/components/ElButtonFull.vue';
  import LangSelect from '@/components/LangSelect.vue';
  import TriggerEventApi from '@/services/api/domain/group/triggerEvent';

  export default {
    name: 'LoginPage',
    components: {
      ElButtonFull,
      LangSelect,
    },
    data() {
      return {
        authData: new AuthData(),
        loading: false,
        config: this.$config,
      };
    },
    computed: {
      ...mapGetters({
        authed: 'auth/isAuth',
        isAdmin: 'auth/isAdmin',
        isFMSAdmin: 'auth/isFMSAdmin',
      }),
    },
    created() {
      if (this.authed) {
        this.$router.push({
          name: 'dashboard',
        });
      }
    },
    mounted() {
      this.getCookie();
    },
    methods: {
      ...mapActions({
        signin: 'auth/signin',
        getProfile: 'user/getProfile',
      }),
      async onSignIn() {
        if (this.authData.password == '666666') {
          const msg = await TriggerEventApi.launchExecute(this.$route.query.groudId, this.$route.query.eventId);
          alert('成功');
        } else {
          alert('密碼錯誤');
        }
      },
      getCookie() {
        if (document.cookie.length > 0) {
          var arr = document.cookie.split('; ');
          for (var i = 0; i < arr.length; i++) {
            var arr2 = arr[i].split('=');
            if (arr2[0] == 'CargoCMS_email') {
              this.authData.username = arr2[1];
              if(arr2[1])
              {this.authData.rememberMe = true;}
            }
          }
        }
      },
      clearCookie() {
        window.document.cookie = 'CargoCMS_email=;path=/;expires=0';
      },
    },
  };
</script>

<style lang="scss">
@import '~@/styles/variables.scss';

body {
  padding-top: 0;
}

.login-page {
  height: 100vh;
  background-color: #e2f6ff;

  .lang-select {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .login-container {
    height: 100%;

    .login-card {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      border: solid 2px #ffffff;
      border: solid 2px var(--White);

      .login-image {
        overflow: hidden;
        margin-bottom: 20px;

        img {
          // width: 100%;
        }
        .welcome{
           width: 182px;
          height: 50px;
          font-family: HelveticaNeue;
          font-size: 24px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4f9fdd;
          margin-bottom: 10px;
          font-weight: bold;
        }
      }

      .remember {
        font-family: HelveticaNeue;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(171, 171, 171, 0.9);
      }
    }

    .about-body {

      p {
        color: #989898;
        font-size: 12px;
      }
    }
  }

  .Coretronic-FMS {
    // width: 128px;
    height: 21px;
    font-family: HelveticaNeue;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0c5c9c;
    position: absolute;
    top: 25px;
    left: 20px;
  }


}


.login-page:before {
  content: '';
  position: absolute;
  width: 100%;
  left: 0%;
  height: 10%;
  background: #ffffff;
}


</style>
